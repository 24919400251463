import React from "react";
import classes from './Footer.module.css';
import logo from '../assets/icons/iconLogo.svg';

function Footer() {
    return ( <footer className = { classes.footer } >
        <div className = {classes.footer_container }>
            <div>
                <h3 className = { classes.h3 }> 
                    © 2021, 1UP Labs 
                </h3> 
            </div>
            <div> 
                <img 
                    src = { logo }
                    className = { classes.logo }
                    alt = 'Logo' 
                />
            </div>  
            <div className = { classes.terms }>
                <a className = { classes.text } href = "/"> 
                    Privacy & Cookie Policy 
                </a> 
                <span>|</span>
                <a className={classes.text} href="/">
                    Terms of Service
                </a> 
            </div>  
        </div>
        </footer>
    );
}

export default Footer;
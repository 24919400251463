import React, {useState} from 'react'
import classes from './Gallery.module.css';
import { CSSGrid, layout} from 'react-stonecutter';

import portfolio from './external';

const GalleryProjects = () => {
    
    const [items, setItems] = useState(portfolio);

    const filterItem = (categItem) =>{
        const updatedItems = portfolio.filter((curElem) => {
            return curElem.category === categItem;
        })

        setItems(updatedItems);
    }

    return (
        <>
            <div className={classes.container}>
                <div className='row'>
                    <div className={classes.header}>
                        <h2>Our Works</h2>
                        <div className={classes.tab}>
                            <button className={classes.button} onClick = {() => setItems(portfolio)}>All</button>
                            <button className={classes.button} onClick = {() => filterItem('Website')}>Website</button>
                            <button className={classes.button} onClick = {() => filterItem('App')}>App</button>
                            <button className={classes.button} onClick = {() => filterItem('Platform')}>Platform</button>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <CSSGrid
                        component="ul"
                        columns={6}
                        columnWidth={290}
                        gutterWidth={5}
                        gutterHeight={5}
                        layout={layout.pinterest}
                        duration={500}
                        easing="ease-out"
                    >
                
                    {
                        items.map((elem) => {
                            const {id, name, image, cetgory} = elem;

                            return(
                                <li key={id} itemHeight={150}>
                                    <img src={image} className="img-fliud" />
                                </li>
                            )

                        })
                    }
                    </CSSGrid>
                </div>
            </div>
        </>
    )
}

export default GalleryProjects
import React from 'react'

const AboutSerious = props => {


  return (
    <>
      <h1>About Screen</h1>
      <h1>serious</h1>
    </>
  )
}

export default AboutSerious
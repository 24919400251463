/* eslint-disable jsx-a11y/heading-has-content */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

//import images
import topBackground from '../../assets/webTop.png'
import bottomBackground from '../../assets/webBottom.png'
import topBackgroundHover from '../../assets/webTopHover.png'
import bottomBackgroundHover from '../../assets/webBottomHover.png'

import classes from './Choose.module.css'

import { setYourSide } from '../../store/action'
import SocialBtns from '../../components/SocialBtns'


const Choose = props => {

  const [hoverTop, setHoverTop] = useState(false)
  const [hoverBottom, setHoverBottom] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()

  return (
    <>
      <div className={classes.container}>

        <div className={classes.chooseVersionContainer}>
          <span className={classes.sideTxt}>CHOOSE YOUR SIDE</span>
        </div>

        {/* top container; serious side */}
        <div
          className={classes.topBackgroundStyle}
          style={{ backgroundImage: hoverTop ? `url(${topBackgroundHover})` : `url(${topBackground})` }}
          onPointerOver={() => setHoverTop(true)}
          onPointerOut={() => setHoverTop(false)}
          onClick={() => {
            dispatch(setYourSide(0));
            history.push('/home')
          }}
        >
          {hoverTop ? <h1 className={classes.textTop}>SERIOUS ONE</h1> : <h1></h1>}
        </div>

        {/* bottom container; crazy side */}
        <div
          className={classes.bottomBackgroundStyle}
          style={{ backgroundImage: hoverBottom ? `url(${bottomBackgroundHover})` : `url(${bottomBackground})` }}
          onPointerOver={() => setHoverBottom(true)}
          onPointerOut={() => setHoverBottom(false)}
          onClick={() => {
            dispatch(setYourSide(1));
            history.push('/home/creative');
          }}
        >
          {hoverBottom ? <h1 className={classes.textBottom}>CRAZY ONE</h1> : <h1></h1>}
        </div>

        {/* Social Icons */}
        <SocialBtns dark={true} />
  
      </div>
    </>
  )
}

export default Choose
import React, { useEffect, useRef } from 'react'
import * as THREE from 'three'

import earth from '../../assets/portfolio/earth1.jpg'

const ContactCrazy = props => {

  const sphereRef = useRef();

  useEffect(() => {

    let width = sphereRef.current.clientWidth
    let height = sphereRef.current.clientHeight
    let frameId

    const scene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000)
    const renderer = new THREE.WebGLRenderer({ antialias: true })

    function sphereVertex() {
      return `
        varying vec2 vertexUV;
        varying vec3 vertexNormal;
        void main(){
          vertexUV = uv;
          vertexNormal = normalize(normalMatrix * normal);
          gl_Position = projectionMatrix*modelViewMatrix*vec4(position,1.0);
        }
      `
    }

    function sphereFragment() {
      return `
        uniform sampler2D globeTexture;
        varying vec2 vertexUV;
        varying vec3 vertexNormal;
        void main(){
          gl_FragColor = vec4(texture2D(globeTexture, vertexUV).xyz, 1.0);
        }
      `
    }


    const sphere = new THREE.Mesh(new THREE.SphereGeometry(5, 50, 50), new THREE.ShaderMaterial(
      {
        vertexShader: sphereVertex(),
        fragmentShader: sphereFragment(),
        uniforms: {
          globeTexture: {
            value: new THREE.TextureLoader().load(earth)
          }
        }
      }
    ))

    camera.position.z = 20
    scene.add(sphere)

    renderer.setSize(width, height)
    renderer.setPixelRatio(window.devicePixelRatio)

    const renderScene = () => {
      renderer.render(scene, camera)
    }

    const handleResize = () => {
      width = sphereRef.current.clientWidth
      height = sphereRef.current.clientHeight
      renderer.setSize(width, height)
      camera.aspect = width / height
      camera.updateProjectionMatrix()
      renderScene()
    }

    const animate = () => {
      //sphere.rotation.x += 0.01
      sphere.rotation.y += 0.01

      renderScene()
      frameId = window.requestAnimationFrame(animate)
    }


    sphereRef.current.appendChild(renderer.domElement)
    window.addEventListener('resize', handleResize)
    animate()


    return () => {
      window.removeEventListener('resize', handleResize)
      sphereRef.current.removeChild(renderer.domElement)

      scene.remove(sphere)
    }

  }, []);


  return (
    <div style={{ width: '100vw', height: '100vh', backgroundColor: '#000' }} ref={sphereRef}>
    </div>
  )
}

export default ContactCrazy;
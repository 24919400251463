import React from 'react'
import classes from './Portfolio.module.css'

import GalleryProjects from '../../components/Projects/GalleryProjects'

const PortfolioSerious = props => {
    return (
        <div className='container'>
            <GalleryProjects />
        </div>
    )
}

export default PortfolioSerious
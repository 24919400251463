import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

import classes from './PortfolioCrazy.module.css'

const PortfolioCrazy = () => {

  const projectImages = [
    { id: 1, path: require('../../assets/home/img1.png') },
    { id: 2, path: require('../../assets/home/img2.png') },
    { id: 3, path: require('../../assets/home/img3.png') },
    { id: 4, path: require('../../assets/home/img4.png') },
    { id: 5, path: require('../../assets/home/img5.png') },
    { id: 6, path: require('../../assets/home/img6.png') },
    { id: 7, path: require('../../assets/home/img7.png') },
    { id: 8, path: require('../../assets/home/img8.png') },
    { id: 9, path: require('../../assets/home/img9.png') },
  ]

  return (
    <motion.div
      initial={{ y: '100vh' }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
      exit={{ y: '100vh' }}
    >
      <div className={classes.container}>

        <Link to="/portfolio/project/creative/:1" >
          Go to project
        </Link>

        {/* {projectImages.map(item => {
          return (
            <img src={item.path.default} style={{ objectFit: 'contain', position: 'absolute', top: Math.floor((Math.random() * window.innerHeight)), left: Math.floor((Math.random() * (window.innerWidth - 200))) }} />
          )
        })} */}
      </div>
    </motion.div>
  )

}



export default PortfolioCrazy
import React, { useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { gsap } from 'gsap'
import Draggable from 'gsap/Draggable'

import classes from './HomeCrazy.module.css'


const HomeCrazy = props => {

  let flyingSaucer = useRef()

  useEffect(() => {

    if (typeof window !== "undefined") {
      gsap.registerPlugin(Draggable)
    }

    Draggable.create(flyingSaucer.current, {
      type: "x,y",
      bounds: window,
      inertia: true
    });

  }, [])


  return (
    <div className={classes.container}>

      <img
        src={require('../../assets/home/astronaut.png').default}
        alt="Astronauts"
        className={classes.astrounautsImage}
        ref={flyingSaucer}
      />

      <div className={classes.contentContainer} >
        <Link to="/portfolio/creative" className={classes.linkContainer}>
          <p className={classes.linkText}>Our Work</p>
          <img
            src={require('../../assets/icons/downArrow.png').default}
            alt="Down Arrow"
            className={classes.linkImg}
          />
        </Link>
      </div>

    </div>
  )
}

export default HomeCrazy
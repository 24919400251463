import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';

import classes from './Header.module.css';
import Menu from './Menu';
import SocialBtns from './SocialBtns';

const Header = (props) => {

  //toggle menu 
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);


  const location = useLocation();


  const side = useSelector(state => state.side);


  //menu data
  const SidebarData = [
    { title: 'About us', path: side ? '/about/creative' : '/about' },
    { title: 'Portfolio', path: side ? '/portfolio/creative' : '/portfolio' },
    { title: 'Blog', path: "/blog" },
    { title: 'Contact us', path: side ? '/contact/creative' : '/contact' }
  ];

  let navClass = classes.NavBarHide;
  if (sidebar) {
    navClass = classes.NavBarShow;
  } else {
    navClass = classes.NavBarHide;
  }

  return (
    <>
      {!sidebar && <div className={classes.NavBar}>
        <div className={classes.TopNav}>
          <Link to='/'>
            <img src={require("../assets/icons/1upLogo.png").default} alt="1up logo" className={classes.logo} />
          </Link>
          <div className={classes.rightContainer}>
            <Link to='#' style={{ textDecoration: 'none' }} onClick={showSidebar}>
              <Menu dark={(location.pathname === '/contact') ? true : false} />
            </Link>
          </div>
        </div>
      </div>}


      <nav className={navClass}>
        {/* Top menu */}
        <div className={classes.TopNav}>
          <Link to='/'>
            <img src={require("../assets/icons/1upLogo.png").default} alt="1up logo" className={classes.logo} onClick={showSidebar} />
          </Link>
          <div className={classes.rightContainer}>
            <Link to='#' onClick={showSidebar} style={{ textDecoration: 'none' }}>
              <Menu close={true} dark={true} />
            </Link>
          </div>
        </div>

        {/* Menu items  */}
        <div className={classes.menuLinksContainer} >
          {SidebarData.map((item, index) => {
            return (
              <div key={index} className={classes.menuLinks} onClick={showSidebar} >
                <p className={classes.menuNumbers}> {index + 1}.   &nbsp;  {!index && <>&#8201;</>}</p>
                <Link to={item.path} className={classes.linkItem}>
                  {item.title}
                </Link>
              </div> 
            );
          })}

          {/* Address Info */}
          <div className={classes.addressInfo}>
            <p className={classes.address}>Get in Touch </p>
            <p className={classes.addressText}>
              St. Themistokli Germenji, 1001, Tiranë <br />
              info@1up.al
            </p>
          </div>

          {/* Social Icons */}
          <SocialBtns style={{ right: 70, left: 'auto' }} />

          <div to='/' onClick={showSidebar} className={classes.backContainer}>
            <img src={require('../assets/icons/backIcon.png').default} style={{ objectFit: 'contain', width: 25, marginRight: 10 }} />
            <p className={classes.backText}>BACK TO HOME</p>
          </div>
          <div className={classes.hrLine}></div>
        </div>
      </nav>
    </>
  );
}

export default Header;
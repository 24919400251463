import React from 'react'

const ContactSerious = props => {
  return (
    <>
      <h1>Contact Screen</h1>
      <h1>serious</h1>
    </>
  )
}

export default ContactSerious
const portfolio = [
	
	{
		"id": 1,
		"title": "Vodafone City",
		"image": "/static/media/1upLogo.09249cdd.png",
		"link": "#",
		"category": "App"
	},
	{
		"id": 2,
		"name": "Vodafone More",
		"image": "/static/media/1upLogo.09249cdd.png",
		"link": "#",
		"category": "Platform"
	},
	{
		"id": 3,
		"name": "JUTH",
		"image": "/static/media/1upLogo.09249cdd.png",
		"link": "#",
		"category": "App"
	},
	{
		"id": 4,
		"name": "Veko App",
		"image": "/static/media/1upLogo.09249cdd.png",
		"link": "#",
		"category": "App"
	},
	{
		"id": 5,
		"name": "Makina ime",
		"image": "/static/media/1upLogo.09249cdd.png",
		"link": "#",
		"category": "App"
	},
	{
		"id": 6,
		"name": "eBiznes",
		"image": "/static/media/1upLogo.09249cdd.png",
		"link": "#",
		"category": "Platform"
	},
	{
		"id": 7,
		"name": "Akep Website",
		"image": "/static/media/1upLogo.09249cdd.png",
		"link": "#",
		"category": "Website"
	},
	{
		"id": 8,
		"name": "Veko Website",
		"image": "/static/media/1upLogo.09249cdd.png",
		"link": "#",
		"category": "Website"
	},
	{
		"id": 9,
		"name": "Alpha Prona",
		"image": "/static/media/1upLogo.09249cdd.png",
		"link": "#",
		"category": "Website"
	},
	{
		"id": 10,
		"name": "Euronews Website",
		"image": "/static/media/1upLogo.09249cdd.png",
		"link": "#",
		"category": "Website"
	},
	{
		"id": 11,
		"name": "Biblioteka Korce Software",
		"image": "/static/media/1upLogo.09249cdd.png",
		"link": "#",
		"category": "Platform"
	},
	{
		"id": 12,
		"name": "Lekli Website",
		"image": "/static/media/1upLogo.09249cdd.png",
		"link": "#",
		"category": "Website"
	},
	{
		"id": 13,
		"name": "Ferma 100 Website",
		"image": "/static/media/1upLogo.09249cdd.png",
		"link": "#",
		"category": "Website"
	},
	{
		"id": 14,
		"name": "Raiffeisen Loyalty",
		"image": "/static/media/1upLogo.09249cdd.png",
		"link": "#",
		"category": "Platform"
	}
];

export default portfolio
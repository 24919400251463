import React from "react";

import classes from './SocialBtn.module.css'

const SocialBtns = props => {


  const instagramLogo = props.dark ? require('../assets/icons/instagram-dark.png') : require('../assets/icons/instagram.png');
  const fbLogo = props.dark ? require('../assets/icons/fb-dark.png') : require('../assets/icons/fb.png');
  const beLogo = props.dark ? require('../assets/icons/be-dark.png') : require('../assets/icons/be.png');
  const linkedinLogo = props.dark ? require('../assets/icons/linkedin-dark.png') : require('../assets/icons/linkedin.png');

  return (
    <div
      className={props.dark ? classes.darkSocialContainer : classes.socialContainer}
      style={props.style}
    >
      <a href="https://www.instagram.com/1up_labs">
        <img src={instagramLogo.default} alt="instagram logo" className={props.dark ? classes.darkSocialIcon : classes.socialIcon} />
      </a>
      <a href="https://www.facebook.com/1UPinteractive">
        <img src={fbLogo.default} alt="facebook logo" className={props.dark ? classes.darkSocialIcon : classes.socialIcon} />
      </a>
      <a href="https://www.linkedin.com/company/1up-labs">
        <img src={linkedinLogo.default} alt="linkedin logo" className={props.dark ? classes.darkSocialIcon : classes.socialIcon} />
      </a>
    </div>
  )
}

export default SocialBtns;
import React from 'react'

import classes from './HomeSerious.module.css'


const HomeSerious = props => {

  return (
    <div className={classes.container}>
    </div>
  )
}

export default HomeSerious
import { SET_SIDE } from './action';

const sideFromLocalStorage = sessionStorage.getItem('side');

const initialState = {
  side: sideFromLocalStorage ? sideFromLocalStorage : null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SIDE:
      sessionStorage.setItem('side', action.side);
      return {
        ...initialState,
        side: action.side,
      };
    default:
      return state;
  }
}
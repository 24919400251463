import React from 'react'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AnimatePresence } from 'framer-motion'

//import screens
import HomeCrazy from './screens/HomeCrazy'
import HomeSerious from './screens/HomeSerious'
import AboutCrazy from './screens/AboutCrazy'
import AboutSerious from './screens/AboutSerious'
import PortfolioCrazy from './screens/PortfolioCrazy'
import PortfolioSerious from './screens/PortfolioSerious'
import PortfolioCrazyProject from './screens/PortfolioCrazyProject'
import PortfolioSeriousProject from './screens/PortfolioSeriousProject'
import Blog from './screens/Blog'
import ContactCrazy from './screens/ContactCrazy'
import ContactSerious from './screens/ContactSerious'
import Choose from './screens/ChooseScreen'

//import components
import Footer from './components/Footer'
import Header from './components/Header'

import './App.css'

const App = () => {

    const side = useSelector(state => state.side)

    return (
        <BrowserRouter >
            < Header />
            <AnimatePresence exitBeforeEnter initial={false}>
                <Switch >
                    <Route path='/' exact component={Choose} />
                    <Route path={side ? '/home/creative' : '/home'} component={side ? HomeCrazy : HomeSerious} />
                    <Route path={side ? '/about/creative' : '/about'} component={side ? AboutCrazy : AboutSerious} />
                    <Route path={side ? '/portfolio/creative' : '/portfolio'} component={side ? PortfolioCrazy : PortfolioSerious} />
                    <Route path='/blog' component={Blog} />
                    <Route path={side ? '/contact/creative' : '/contact'} component={side ? ContactCrazy : ContactSerious} />
                    <Route path="/portfolio/project/creative/:id" component={PortfolioCrazyProject} />
                    <Route path="/portfolio/project" component={PortfolioSeriousProject} />
                </Switch>
            </AnimatePresence>
            {/* <Footer /> */}
        </BrowserRouter>
    );
}

export default App

import React from 'react'

const AboutCrazy = props => {
  return (
    <>
      <h1>About Screen</h1>
      <h1>crazy</h1>
    </>
  )
}

export default AboutCrazy
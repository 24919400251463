import React from 'react'


const Blog = props => {
  return (
    <>
      <h1>Blog Screen</h1>
    </>
  )
}

export default Blog